import { colors, fonts } from '../../../../theme';
import { position } from 'polished';
import { transitions } from 'styled-gen';
import styled from 'styled-components';

const HeaderMenu = styled.ul`
  margin-top: 2rem;
`;

const HeaderMenuItem = styled.li`
  a {
    color: ${colors.n04};
    font-size: 0.75rem;
    font-weight: ${fonts.weights.semibold};
    letter-spacing: 0.5px;
    line-height: 0.875rem;
    position: relative;

    &.active {
      color: ${colors.n06};

      &::before {
        ${position('absolute', 0, null, 0, '-0.5rem')};

        background-color: ${colors.p06};
        content: '';
        height: 100%;
        width: 1px;
      }
    }

    &:hover {
      &:not(.active) {
        color: ${colors.n05};
      }
    }
  }
`;

const HeaderWrapper = styled.div`
  ${transitions('opacity', 250, 'linear')};

  height: 0;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  position: fixed;
  width: 100%;
  z-index: 5;
`;

export { HeaderMenu, HeaderMenuItem, HeaderWrapper };
