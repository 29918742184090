import { Col, Grid, Row, XLarge } from '../../../../theme/components';
import { CtaInput } from './CtaInput';
import { Section } from '../../../../components/Section/Section';
import { SubmitContact } from '../../../../services/SubmitContact';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

export const Cta = props => {
  const [state, setState] = useState({ isLoading: false, isSent: false, withError: false });
  const { heading } = props;

  const input = extractFromCamelCase(props, 'input');
  const { error, success } = extractFromCamelCase(input, 'message');

  const { isLoading, isSent, withError } = state;

  const handleSubmit = async value => {
    if (isLoading) {
      return;
    }

    setState(previous => ({ ...previous, isLoading: true }));

    try {
      await SubmitContact.submit(value);

      setState(previous => ({ ...previous, isLoading: false, isSent: true }));
    } catch (error) {
      setState(previous => ({ ...previous, isSent: true, withError: true }));
    }
  };

  return (
    <Section heading={heading} headingProps={{ p06: true }} id="requestCallback" light>
      <Grid mt={1.5}>
        <Row>
          {!isSent ? (
            /* eslint-disable-next-line react/jsx-sort-props */
            <Col lg={5} md={7} sm={9} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2}>
              <CtaInput {...input} isLoading={isLoading} name="contact" onSubmit={handleSubmit} />
            </Col>
          ) : (
            /* eslint-disable-next-line react/jsx-sort-props */
            <Col lg={8} md={7} sm={9} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2}>
              <XLarge>{withError ? error : success}</XLarge>
            </Col>
          )}
        </Row>
      </Grid>
    </Section>
  );
};

Cta.propTypes = {
  heading: PropTypes.string
};
