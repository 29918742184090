import * as slices from '../slices';
import { CommonDataProvider } from '../components/CommonDataProvider/CommonDataProvider';
import { Layout } from '../components/Layout';
import { PrismicReactToolsProvider, extractFromCamelCase } from 'prismic-react-tools';
import { get } from 'lodash';
import { getSeoFromDocument } from '../lib/getSeoFromDocument';
import { serializer } from '../lib/serializer';
import NotFound from '../pages/404';
import PropTypes from 'prop-types';
import React from 'react';
import localesFromConfig from '../../langs-config';

const url = process.env.GATSBY_URL;

export default (Page, layoutData) => {
  const withPrismicPage = props => {
    const { location, pageContext, uri } = props;
    const common = get(pageContext, 'common');

    if (!common) {
      return <NotFound />;
    }

    const document = get(pageContext, 'document');
    const lang = get(pageContext, 'lang');
    const locale = localesFromConfig[lang];

    const layout = {
      locale,
      location,
      seo: {
        defaultMeta: {
          ...extractFromCamelCase(common, 'seo'),
          url: `${url}/${uri}`
        },
        meta: {
          ...getSeoFromDocument(document),
          url: `${url}/${uri}`
        }
      },

      ...layoutData
    };

    const locales = Object.keys(localesFromConfig).reduce(
      (acc, locale) => ({ ...acc, [localesFromConfig[locale].path]: localesFromConfig[locale] }),
      {}
    );

    return (
      <CommonDataProvider common={common} locale={locale.path} locales={locales} location={location}>
        <PrismicReactToolsProvider components={slices} serializer={serializer}>
          <Layout {...layout}>
            <Page {...props} />
          </Layout>
        </PrismicReactToolsProvider>
      </CommonDataProvider>
    );
  };

  withPrismicPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    uri: PropTypes.string
  };

  return withPrismicPage;
};
