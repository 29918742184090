import { colors } from '../../theme';
import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg'
})`
  position: relative;
  z-index: 10;
`;

export const Curve = props => {
  return (
    <SVG {...props} viewBox="0 0 1440 98">
      <path d="M711 98C427.6 98 173.4 65.5 0 14V0h1440v8.5C1266.5 63.2 1004.3 98 711 98z" fill={colors.n06} />
    </SVG>
  );
};
