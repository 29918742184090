import { Card, CardContent, Div, IconButton, Small, XSmall } from '../../../../theme/components';
import { PeopleCardContent, PeopleCardPhoto } from './PeopleCard.style';
import { RichText } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';

export const PeopleCard = props => {
  const {
    description,
    linkedin,
    name,
    photo: { url: photo },
    position,
    twitter
  } = props;

  return (
    <Card h="100%">
      <CardContent>
        <PeopleCardPhoto photo={photo} />
        <PeopleCardContent>
          <Div>
            <Div flex="flex-end space-between">
              <Small p06 semibold>
                {name}
              </Small>
              {(linkedin || twitter) && (
                <Div flex="flex-end flex-start" mt={-0.25}>
                  {linkedin && <IconButton href={linkedin} icon="linkedin" />}
                  {twitter && <IconButton href={twitter} icon="twitter" ml={linkedin ? 1 : 0} />}
                </Div>
              )}
            </Div>
            <Div mt={0.5}>
              <XSmall n04 semibold>
                {position}
              </XSmall>
            </Div>
          </Div>
          <Small as="div" light mt={1}>
            <RichText content={description} />
          </Small>
        </PeopleCardContent>
      </CardContent>
    </Card>
  );
};

PeopleCard.propTypes = {
  description: PropTypes.any,
  linkedin: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.shape({
    url: PropTypes.string
  }),
  position: PropTypes.string,
  twitter: PropTypes.string
};
