import { colors, fonts } from '../../variables';
import { generateProps, mq } from 'styled-gen';
import styled, { css } from 'styled-components';

const commonStyle = css`
  font-family: ${fonts.families.sans};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
    `};

  ${generateProps}
`;

// Headings

const commonHeadingStyle = css`
  ${commonStyle};

  font-weight: ${fonts.weights.extrabold};
  line-height: 1.2;
`;

export const headingStyles = {
  h1: css`
    color: ${colors.n06};

    ${commonHeadingStyle};

    font-size: 2.25rem;

    ${mq.tabletLandscape(css`
      font-size: 3rem;
    `)}
  `,

  h2: css`
    ${commonHeadingStyle};

    font-size: 1.75rem;

    ${mq.tabletLandscape(css`
      font-size: 2.375rem;
    `)}
  `,

  h3: css`
    ${commonHeadingStyle};

    font-size: 1.25rem;

    ${mq.tabletLandscape(css`
      font-size: 1.625rem;
    `)}
  `
};

export const Heading1 = styled.h1`
  text-transform: lowercase;

  ${headingStyles.h1};
`;

export const Heading2 = styled.h1`
  text-transform: lowercase;

  ${headingStyles.h2};
`;

export const Heading3 = styled.h1`
  ${headingStyles.h3};
`;

// Body

const commonBodyTextStyle = css`
  ${commonStyle};

  line-height: 1.75;

  p + p {
    margin-top: 0.75rem;
  }
`;

export const textStyles = {
  medium: css`
    font-size: 1rem;
    font-weight: ${fonts.weights.light};

    ${commonBodyTextStyle};
  `,

  paragprah: css`
    font-size: 1rem;
    font-weight: ${fonts.weights.light};

    ${mq.tabletLandscape(css`
      font-size: 1.125rem;
    `)}

    ${commonBodyTextStyle};
  `,

  small: css`
    font-size: 0.875rem;
    font-weight: ${fonts.weights.light};

    ${commonBodyTextStyle};
  `,

  xlarge: css`
    font-size: 1.375rem;

    b {
      font-weight: ${fonts.weights.extrabold};
    }

    ${mq.tabletLandscape(css`
      font-size: 1.75rem;
    `)}

    ${commonBodyTextStyle};
  `,

  xsmall: css`
    ${commonBodyTextStyle};

    font-size: 0.75rem;

    ${commonBodyTextStyle};
  `,

  xxsmall: css`
    ${commonBodyTextStyle};

    font-size: 0.625rem;
    letter-spacing: 0.05rem;

    ${commonBodyTextStyle};
  `
};

export const XLarge = styled.p`
  ${textStyles.xlarge};

  line-height: 1.4;
`;

export const Paragprah = styled.p`
  ${textStyles.paragprah};
`;

export const Medium = styled.p`
  ${textStyles.medium};
`;

export const Small = styled.p`
  ${textStyles.small};
`;

export const XSmall = styled.p`
  ${textStyles.xsmall};
`;

export const XXSmall = styled.p`
  ${textStyles.xxsmall};
`;
