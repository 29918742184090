import styled from 'styled-components';

const PeopleCardPhoto = styled.div`
  height: 11.25rem;
  width: 100%;
  background-image: url('${({ photo }) => photo}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const PeopleCardContent = styled.div`
  padding: 1.5rem 2rem 2.5rem;
`;

export { PeopleCardContent, PeopleCardPhoto };
