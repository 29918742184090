export const colors = {
  e06: '#ff5858',

  n01: '#ffffff',
  n02: '#f3f6fd',
  n03: '#e2e7ed',
  n04: '#acb1b9',
  n05: '#585e66',
  n06: '#0f0f0f',

  p06: '#153cff',
  p07: '#0531a7'
};
