import { Heading1, Heading2, Heading3 } from '../theme/components';
import { bracked } from 'prismic-react-tools';
import React from 'react';
import parse from 'html-react-parser';

const headings = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3
};

/* eslint-disable-next-line max-params */
export const serializer = (variables, type, element, children, content, key) => {
  const { data, text } = element;

  if (type.startsWith('heading')) {
    const headingType = `${type.replace('heading', 'h')}`;
    const Heading = headings[headingType];

    if (Heading) {
      return <Heading key={key}>{parse(bracked(content, variables))}</Heading>;
    }

    return null;
  }

  if (type !== 'span' && type !== 'hyperlink' && type !== 'image') {
    return null;
  }

  if (type === 'span') {
    return parse(bracked(text, variables));
  }

  if (type === 'image') {
    const { copyright: href, url: src } = element;

    if (href) {
      return (
        <a className="hst-rich-text-image-link" href={href} key={key} rel="noreferrer noopener" target="_blank">
          <img className="hst-rich-text-image" key={key} src={src} />
        </a>
      );
    }

    return <img className="hst-rich-text-image" src={src} />;
  }

  const { url } = data;

  return (
    <a href={url} key={key} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
};
