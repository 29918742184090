import { colors } from '../../theme';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg'
})`
  color: ${rgba(colors.n02, 1)};
`;

export const FourOFourNumber = props => {
  return (
    <SVG {...props} viewBox="0 0 1145.5 487.815">
      <path
        d="M205.767 408.329v73.717h116.665v-73.717h41.025v-90.384h-41.025V67.307c0-46.153-25-67.307-75-67.307-35.9 0-61.538 14.743-78.845 44.871L15.384 305.125C7.692 318.586 0 335.253 0 353.842c0 31.41 16.666 54.487 53.2 54.487zm0-90.384h-78.2l78.2-135.255zm412.175 15.385c0 37.82-13.461 58.333-45.512 58.333-31.41 0-44.871-21.795-44.871-58.333V154.485c0-37.179 13.461-57.692 44.871-57.692 32.051 0 45.512 21.154 45.512 57.692zm119.229-175C737.171 56.41 679.48 0 571.788 0c-105.768 0-163.46 57.692-163.46 158.973v171.152c0 101.281 58.333 157.691 165.383 157.691s163.46-57.051 163.46-158.973zm250.638 250v73.717h116.665v-73.718h41.026v-90.384h-41.025V67.307c0-46.153-25-67.307-75-67.307-35.9 0-61.538 14.743-78.845 44.871L797.427 305.125c-7.692 13.461-15.384 30.128-15.384 48.717 0 31.41 16.667 54.487 53.2 54.487zm0-90.384h-78.2l78.2-135.255z"
        fill="currentColor"
      />
    </SVG>
  );
};
