import { Button, Div, XXSmall } from '../../../../theme/components';
import { CtaInputType, CtaInputWrapper } from './CtaInput.style';
import { validateEmail } from '../../../../lib/validateEmail';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const CtaInput = props => {
  const [value, setValue] = useState('');
  const [valid, setValid] = useState(false);
  const [blurred, setBlurred] = useState(false);
  const { buttonLabel, isLoading, onSubmit, placeholder } = props;

  const handleBlur = () => setBlurred(true);

  const handleChange = event => setValue(event.target.value);

  const handleSubmit = event => {
    event.preventDefault();

    if (!blurred) {
      setBlurred(true);
    }

    if (!valid) {
      return;
    }

    return onSubmit(value);
  };

  useEffect(() => {
    const validEmail = validateEmail(value) || value.length > 8;

    if (validEmail !== valid) {
      setValid(validEmail);
    }
  }, [value]);

  return (
    <>
      <CtaInputWrapper noValidate>
        <CtaInputType onBlur={handleBlur} onChange={handleChange} placeholder={placeholder} />
        <Button isLoading={isLoading} mt={{ sm: 0, xs: 1 }} onClick={handleSubmit} value={value}>
          {buttonLabel}
        </Button>
      </CtaInputWrapper>
      {!valid && blurred && (
        <Div mt={0.5} pl={1.5}>
          <XXSmall e06 semibold>
            Please add a valid email or phone!
          </XXSmall>
        </Div>
      )}
    </>
  );
};

CtaInput.propTypes = {
  buttonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string
};
