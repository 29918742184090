import { Button, Card, CardContent, Col, Div, Grid, Row, Small, XSmall } from '../../../../theme/components';
import { Carousel } from '../../../../components/Carousel/Carousel';
import { InsightCoverContent, InsightWrapper, InsightsWrapper } from './Insights.style';
import { Section } from '../../../../components/Section/Section';
import { chunk } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const Insights = props => {
  const { buyNowLabel, comingSoonLabel, heading, items, viewAllLabel, viewAllUrl } = props;

  const insights = chunk(items, 4);

  return (
    <Section
      heading={heading}
      id="insights"
      light
      renderInHeading={() => (
        <Small as="a" href={viewAllUrl} ml={1.5} p06 rel="noreferrer noopener" semibold target="_blank">
          {viewAllLabel}
        </Small>
      )}
    >
      <Grid mt={2.5}>
        <Row>
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col lg={10} md={8} sm={9} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2}>
            <Carousel withBullets>
              {insights.map((items, itemIndex) => (
                <InsightsWrapper key={itemIndex}>
                  {items.map(({ amount, category, image: { url: cover }, title, url }, index) => (
                    <InsightWrapper key={index}>
                      <Card bookCover ml={{ md: 'auto' }} mr={{ md: 'auto' }}>
                        <CardContent bkgImage={cover}>
                          <InsightCoverContent>
                            {amount && url ? (
                              <>
                                <Small n05 semibold>
                                  {amount}
                                </Small>
                                <Button href={url} rel="noreferrer noopener" target="_blank">
                                  {buyNowLabel}
                                </Button>
                              </>
                            ) : (
                              <Small n05 semibold>
                                {comingSoonLabel}
                              </Small>
                            )}
                          </InsightCoverContent>
                        </CardContent>
                      </Card>
                      <Div maxWidth={11.5} mt={1.5} pl={1}>
                        <Small as="a" ellipsis href={url} p06 rel="noreferrer noopener" semibold target="_blank">
                          {title}
                        </Small>
                        <XSmall ellipsis mb={{ md: 0, xs: 1.5 }} mt={0} n04 semibold>
                          {category}
                        </XSmall>
                      </Div>
                    </InsightWrapper>
                  ))}
                </InsightsWrapper>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Insights.propTypes = {
  buyNowLabel: PropTypes.string,
  comingSoonLabel: PropTypes.string,
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      category: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string
      }),
      title: PropTypes.string,
      url: PropTypes.string
    })
  ),
  viewAllLabel: PropTypes.string,
  viewAllUrl: PropTypes.string
};
