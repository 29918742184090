import { Col, Grid, Row } from '../../../../theme/components';
import { PartnersCol, PartnersGrid, PartnersItem, PartnersRow } from './Partners.style';
import { Section } from '../../../../components/Section/Section';
import PropTypes from 'prop-types';
import React from 'react';

export const Partners = props => {
  const { heading, items } = props;

  return (
    <Section heading={heading} id="partners">
      <Grid mt={{ md: 4.5, sm: 3.5, xs: 2.25 }}>
        <Row>
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col lg={10} md={8} sm={9} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2}>
            <PartnersGrid>
              <PartnersRow>
                {items.map(({ logo: { url: logo }, url }, index) => (
                  <PartnersCol key={index}>
                    <PartnersItem
                      as={url ? 'a' : 'div'}
                      href={url}
                      rel={url ? 'noreferrer noopener' : undefined}
                      target={url ? '_blank' : undefined}
                    >
                      <img src={logo} />
                    </PartnersItem>
                  </PartnersCol>
                ))}
              </PartnersRow>
            </PartnersGrid>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Partners.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.shape({
        url: PropTypes.string
      }),
      url: PropTypes.string
    })
  )
};
