import { css } from 'styled-components';
import NewJuneBoldWoff from '../../../../static/fonts/newjune/NewJune-bold.woff';
import NewJuneBoldWoff2 from '../../../../static/fonts/newjune/NewJune-bold.woff2';
import NewJuneExtraboldWoff from '../../../../static/fonts/newjune/NewJune-extrabold.woff';
import NewJuneExtraboldWoff2 from '../../../../static/fonts/newjune/NewJune-extrabold.woff2';
import NewJuneLightWoff from '../../../../static/fonts/newjune/NewJune-light.woff';
import NewJuneLightWoff2 from '../../../../static/fonts/newjune/NewJune-light.woff2';
import NewJuneRegularWoff from '../../../../static/fonts/newjune/NewJune-regular.woff';
import NewJuneRegularWoff2 from '../../../../static/fonts/newjune/NewJune-regular.woff2';
import NewJuneSemiboldWoff from '../../../../static/fonts/newjune/NewJune-semibold.woff';
import NewJuneSemiboldWoff2 from '../../../../static/fonts/newjune/NewJune-semibold.woff2';

export const FontFace = css`
  @font-face {
    font-family: 'New June';
    src: local('NewJuneExtrabold'), local('New June Extrabold'), url(${NewJuneExtraboldWoff2}) format('woff2'),
      url(${NewJuneExtraboldWoff}) format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'New June';
    src: local('NewJuneBold'), local('New June Bold'), url(${NewJuneBoldWoff2}) format('woff2'),
      url(${NewJuneBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'New June';
    src: local('NewJuneSemibold'), local('New June Semibold'), url(${NewJuneSemiboldWoff2}) format('woff2'),
      url(${NewJuneSemiboldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'New June';
    src: local('NewJuneRegular'), local('New June Regular'), url(${NewJuneRegularWoff2}) format('woff2'),
      url(${NewJuneRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'New June';
    src: local('NewJuneLight'), local('New June Light'), url(${NewJuneLightWoff2}) format('woff2'),
      url(${NewJuneLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
`;
