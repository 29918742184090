import { colors } from '../../../../theme';
import { mq } from 'styled-gen';
import { rgba, size } from 'polished';
import styled, { css } from 'styled-components';

const FooterBackToTopButton = styled.a`
  ${size('2rem')};

  align-items: center;
  background-color: transparent;
  border-radius: ${2.75 / 2}rem;
  border: 1px solid ${rgba(colors.n01, 0.25)};
  color: ${rgba(colors.n01, 0.25)};
  display: inline-flex;
  justify-content: center;

  ${mq.tabletLandscape(css`
    margin-left: 1.25rem;
  `)}

  svg {
    ${size('1.75rem')};
  }

  ${mq.tabletLandscape(css`
    &:hover {
      background-color: ${rgba(colors.n01, 0.25)};
      color: ${colors.n06};
    }
  `)}
`;

const FooterContent = styled.div`
  background-color: ${colors.n06};
  padding: 3rem 0 5rem;

  ${mq.tabletLandscape(css`
    padding: 4.5rem 0 7.5rem;
  `)}
`;

const FooterCurve = styled.div`
  margin-bottom: -1px;
  position: relative;
  transform: rotate(180deg);
  z-index: 10;
`;

const FooterLogo = styled.div`
  color: ${colors.p06};
  margin-top: 2rem;

  ${mq.tabletLandscape(css`
    margin-top: 3.5rem;
  `)}

  svg {
    ${size('4.125rem')};
  }
`;

const FooterNote = styled.div`
  align-items: center;
  color: ${rgba(colors.n01, 0.7)};
  display: flex;
  font-size: 0.625rem;
  height: 100%;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;

  ${mq.tabletLandscape(css`
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 0;
  `)}
`;

const FooterWrapper = styled.footer`
  margin-top: 5rem;
  position: relative;
  z-index: 10;

  ${mq.tablet(css`
    margin-top: 7.5rem;
  `)}

  ${mq.tabletLandscape(css`
    margin-top: 10rem;
  `)}

  ${mq.desktop(css`
    margin-top: 15rem;
  `)}
`;

export { FooterBackToTopButton, FooterContent, FooterCurve, FooterLogo, FooterNote, FooterWrapper };
