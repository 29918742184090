import {
  CarouselBullet,
  CarouselBullets,
  CarouselItem,
  CarouselItems,
  CarouselLeftGradient,
  CarouselRow,
  CarouselTabButton,
  CarouselTabButtonsWrapper,
  CarouselWrapper
} from './Carousel.style';
import { maxBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

export const Carousel = props => {
  const [activeItem, setActiveItem] = useState(0);
  const [carouselHeight, setCarouselHeight] = useState(0);
  const [calculateHeight, setCalculateHeight] = useState(0);
  const { autoplay, children, duration, normalizeHeight, showOverflow, tabs, withBullets, withGradient } = props;
  const refs = children.map(useRef);

  const goTo = index => {
    const next = index > -1 && index < children.length ? index : 0;

    if (!normalizeHeight) {
      const carouselHeight = refs[index].current.clientHeight;

      setCarouselHeight(carouselHeight);
    }

    setActiveItem(next);
  };

  useEffect(() => {
    const carouselHeight = normalizeHeight
      ? maxBy(refs, 'current.clientHeight').current.clientHeight
      : refs[activeItem].current.clientHeight;

    setCarouselHeight(carouselHeight);
  }, [calculateHeight]);

  useEffect(() => {
    const handleResize = () => {
      setCalculateHeight(prev => !prev);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let autoplayInterval;

    if (autoplay) {
      autoplayInterval = setInterval(() => {
        setActiveItem(activeItem => (activeItem + 1 > -1 && activeItem + 1 < children.length ? activeItem + 1 : 0));
      }, duration);
    }

    return () => {
      if (autoplayInterval) {
        clearInterval(autoplayInterval);
      }
    };
  }, [activeItem, autoplay]);

  return (
    <CarouselWrapper>
      {tabs && (
        <CarouselTabButtonsWrapper>
          {tabs.map((tab, index) => (
            <CarouselTabButton isActive={activeItem === index} key={index} onClick={() => goTo(index)}>
              {tab}
            </CarouselTabButton>
          ))}
        </CarouselTabButtonsWrapper>
      )}
      {withGradient && <CarouselLeftGradient carouselHeight={carouselHeight} withGradient={withGradient} />}
      <CarouselRow carouselHeight={carouselHeight} overflowHidden={!showOverflow}>
        <CarouselItems elHeight={normalizeHeight && carouselHeight}>
          {children.map((child, index) => (
            <CarouselItem activeItem={activeItem} isActive={activeItem === index} key={index} ref={refs[index]}>
              {child}
            </CarouselItem>
          ))}
        </CarouselItems>
      </CarouselRow>
      {withBullets && (
        <CarouselBullets>
          {children.map((_, index) => (
            <CarouselBullet isActive={index === activeItem} key={index} onClick={() => goTo(index)} />
          ))}
        </CarouselBullets>
      )}
    </CarouselWrapper>
  );
};

Carousel.propTypes = {
  autoplay: PropTypes.bool,
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
  normalizeHeight: PropTypes.bool,
  showOverflow: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.string),
  withBullets: PropTypes.bool,
  withGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

Carousel.defaultProps = {
  autoplay: false,
  duration: 10000,
  normalizeHeight: false,
  showOverflow: false,
  withGradient: false
};
