import { Footer, Header, SEO } from './components';
import { GlobalStyle } from '../../theme/components';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

export const Layout = props => {
  const { children, seo } = props;

  return (
    <ThemeProvider theme={theme}>
      <SEO {...seo} />
      <GlobalStyle />
      <Header />
      {children}
      <Footer />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seo: PropTypes.object
};
