import { Col, Grid, Heading1, Row } from '../../../../theme/components';
import { Curve } from '../../../../components/SVGs/Curve';
import { HeroContent, HeroImage, HeroWrapper } from './Hero.style';
import { HeroSlider } from './HeroSlider';
import { PlainText } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';

export const Hero = props => {
  const { heading } = props;

  return (
    <>
      <HeroWrapper>
        <HeroImage />
        <Grid>
          <Row center="xs">
            <Col left lg={12} md={10} sm={8} xs={10}>
              <HeroContent>
                <HeroSlider />
                <Heading1 mt="auto" n01>
                  <PlainText content={heading} />
                </Heading1>
              </HeroContent>
            </Col>
          </Row>
        </Grid>
      </HeroWrapper>
      <Curve />
    </>
  );
};

Hero.propTypes = {
  heading: PropTypes.string
};
