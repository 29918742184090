import { colors } from '../../../../theme';
import { mq } from 'styled-gen';
import styled, { css } from 'styled-components';

const colSpan = 1;

const PartnersCol = styled.div`
  padding: ${colSpan / 2}rem;
  width: 50%;

  ${mq.tablet(css`
    padding: ${colSpan}rem;
    width: 33.3333%;
  `)}
`;

const PartnersItem = styled.div`
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${colors.n02};
  display: flex;
  height: 4rem;
  justify-content: center;
  padding: 1rem;

  ${mq.tabletLandscape(css`
    height: 6rem;
    padding: 1.75rem;
  `)}

  ${mq.desktop(css`
    height: 8rem;
    padding: 2.5rem;
  `)}

  img {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    opacity: 0.2;
    width: auto;
  }

  ${({ as }) =>
    as === 'a' &&
    css`
      &:hover {
        img {
          opacity: 0.5;
        }
      }
    `}
`;

const PartnersGrid = styled.div`
  width: 100%;
`;

const PartnersRow = styled.div`
  margin: -${colSpan / 2}rem;
  display: flex;
  flex-wrap: wrap;

  ${mq.tablet(css`
    margin: -${colSpan}rem;
  `)}
`;

export { PartnersCol, PartnersGrid, PartnersItem, PartnersRow };
