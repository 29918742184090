import { ContactsMenu, ContactsMenuItem, ContactsWrapper } from './Contacts.style';
import { Icon } from '../../../../theme/components';
import { useCommonData } from '../../../CommonDataProvider/CommonDataProvider';
import React, { useMemo } from 'react';

export const Contacts = props => {
  const { contactsSocial, email } = useCommonData();

  const menu = useMemo(() => [...contactsSocial, { name: 'envelope', url: `mailto:${email}` }], []);

  return (
    <ContactsWrapper {...props}>
      <ContactsMenu>
        {menu.map(({ name: icon, url }, index) => (
          <ContactsMenuItem key={index}>
            <a href={url} rel="noreferrer noopener" target="_blank">
              <Icon icon={icon} />
            </a>
          </ContactsMenuItem>
        ))}
      </ContactsMenu>
    </ContactsWrapper>
  );
};
