/* eslint-disable sort-keys */
const weights = {
  extrabold: 800,
  bold: 700,
  semibold: 600,
  regular: 400,
  light: 300
};

const families = {
  sans: 'New June'
};

export const fonts = {
  families,
  weights
};
