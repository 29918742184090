import { Helmet } from 'react-helmet';
import { get, mergeWith } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const getMetaData = metaData =>
  Object.keys(metaData).reduce((meta, metaName) => {
    const metaValue = metaData[metaName];

    if (!metaValue) {
      return meta;
    }

    if (metaName === 'image') {
      return [
        ...meta,
        { content: metaValue, property: `og:${metaName}` },
        { content: metaValue, property: `og:${metaName}:secure_url` },
        { content: metaValue, name: `twitter:${metaName}` }
      ];
    }

    if (metaName === 'keywords') {
      return [...meta, { content: metaValue, name: metaName }];
    }

    if (metaName === 'title') {
      return [
        ...meta,
        { content: metaValue, property: `og:${metaName}` },
        { content: metaValue, name: `twitter:${metaName}` }
      ];
    }

    if (metaName === 'url') {
      return [...meta, { content: metaValue, property: `og:${metaName}` }];
    }

    return [
      ...meta,
      { content: metaValue, name: metaName },
      { content: metaValue, name: `twitter:${metaName}` },
      { content: metaValue, property: `og:${metaName}` }
    ];
  }, []);

export const SEO = ({ defaultMeta: defaultMetaFromProps, meta: metaFromProps }) => {
  const { title: defaultTitle } = defaultMetaFromProps;
  const { title } = metaFromProps;

  const defaultMeta = { ...defaultMetaFromProps, image: get(defaultMetaFromProps, 'image.url') };
  const meta = { ...metaFromProps, image: get(metaFromProps, 'image.url') };

  const mergedMeta = mergeWith(defaultMeta, meta, (defaultValue, value) => value || defaultValue);
  const parsedMeta = getMetaData(mergedMeta);

  return <Helmet meta={parsedMeta} title={title || defaultTitle} />;
};

SEO.propTypes = {
  defaultMeta: PropTypes.object,
  meta: PropTypes.object
};
