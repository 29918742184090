import { Col, Grid, Row } from '../../../../theme/components';
import { Contacts } from '../Contacts/Contacts';
import { HeaderMenu, HeaderMenuItem, HeaderWrapper } from './Header.style';
import { Link } from 'react-scroll';
import { Logo } from '../../../../components/SVGs';
import { useCommonData } from '../../../CommonDataProvider/CommonDataProvider';
import React, { useEffect, useState } from 'react';

const linkProps =
  typeof window !== 'undefined'
    ? {
        delay: 100,
        duration: 500,
        hashSpy: true,
        ignoreCancelEvents: false,
        isDynamic: true,
        offset: -((window.innerHeight - 100) / 2),
        smooth: true,
        spy: true
      }
    : {};

export const Header = () => {
  const { headerMenu } = useCommonData();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const { innerHeight, scrollY } = window;
      const { scrollHeight } = document.documentElement;

      if (innerHeight / 1.25 > scrollY && isVisible) {
        setIsVisible(false);
      }

      if (scrollY > innerHeight / 1.25 && !isVisible && scrollY < scrollHeight - innerHeight * 1.25) {
        setIsVisible(true);
      }

      if (scrollY > scrollHeight - innerHeight * 1.25 && isVisible) {
        setIsVisible(false);
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isVisible]);

  return (
    <HeaderWrapper isVisible={isVisible}>
      <Grid h="0">
        <Row h="0">
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col md={2} mdOffset={1} lgOffset={0} mt="45vh" xs={false}>
            <Logo />
            <HeaderMenu>
              {headerMenu.map(({ label, url }, index) => (
                <HeaderMenuItem key={index}>
                  <Link to={url} {...linkProps}>
                    {label}
                  </Link>
                </HeaderMenuItem>
              ))}
            </HeaderMenu>
            <Contacts mt={2.875} />
          </Col>
        </Row>
      </Grid>
    </HeaderWrapper>
  );
};
