import { Icon } from '../../../../theme/components';
import { colors } from '../../../../theme';
import { ease } from 'styled-gen';
import { rgba, size } from 'polished';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-2.75rem);
  }
  50% { opacity: 1 }
  100% {
    opacity: 0;
    transform: translateY(calc(27vh));
  }
`;

const Arrow = styled.div`
  ${size('2.75rem')};

  align-items: center;
  animation: ${animation} 2s ${ease.outCubic} infinite;
  background-color: transparent;
  border-radius: ${2.75 / 2}rem;
  border: 1px solid ${rgba(colors.n01, 0.25)};
  color: ${rgba(colors.n01, 0.25)};
  display: flex;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transform: translateY(-2.75rem);

  svg {
    ${size('1.75rem')};
  }
`;

const Line = styled.div`
  background-color: ${rgba(colors.n01, 0.035)};
  height: 100%;
  position: absolute;
  width: 2px;
`;

const LineWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 27vh;
  margin-top: 1.5rem;
  position: relative;
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-right: auto;
`;

export const HeroSlider = () => {
  return (
    <Wrapper>
      <Icon icon="ab" p06 size={2.5} />
      <LineWrapper>
        <Line />
        <Arrow>
          <Icon icon="arrowDown" />
        </Arrow>
      </LineWrapper>
    </Wrapper>
  );
};
