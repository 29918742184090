import { Spinner } from '../Spinner/Spinner';
import { colors, fonts } from '../../variables';
import { ease, generateProps, mq, transitions } from 'styled-gen';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const ButtonWrapper = styled.button`
  ${transitions('all', '0.15s', ease.inOutCirc)};

  align-items: center;
  background-color: ${colors.p06};
  border: 0;
  border-radius: 1.625rem;
  box-shadow: 0 0 0 ${rgba(colors.p06, 0)};
  color: ${colors.n01};
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: ${fonts.weights.semibold};
  height: 3.25rem;
  justify-content: center;
  letter-spacing: 0.025rem;
  outline: 0;
  overflow: hidden;
  padding: 0 2rem;
  position: relative;
  text-transform: lowercase;

  ${({ isLoading }) =>
    !isLoading &&
    css`
      &:not(:active),
      &:not(:disabled) {
        cursor: pointer;

        ${mq.tabletLandscape(css`
          &:hover {
            background-color: ${colors.p07};
            box-shadow: 0 0.75rem 0.75rem ${rgba(colors.p06, 0.16)};
          }
        `)}
      }
    `}

  ${generateProps};
`;

export const Button = props => {
  const { children, ...forwardProps } = props;

  return (
    <ButtonWrapper
      as={props.href && 'a'}
      rel={props.href && 'noreferrer noopener'}
      target={props.href && '_blank'}
      {...forwardProps}
    >
      <Spinner isLoading={props.isLoading} />
      {children}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
  isLoading: PropTypes.bool
};
