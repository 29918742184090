import PropTypes from 'prop-types';
import React, { createContext } from 'react';

const CommonDataContext = createContext({});

export const CommonDataProvider = ({ children, common, locale, locales, location }) => (
  <CommonDataContext.Provider value={{ ...common, locale, locales, location }}>{children}</CommonDataContext.Provider>
);

CommonDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  common: PropTypes.object,
  locale: PropTypes.string,
  locales: PropTypes.object,
  location: PropTypes.object
};

export const useCommonData = () => {
  const context = React.useContext(CommonDataContext);

  return context;
};

export const CommonDataConsumer = CommonDataContext.Consumer;
