import { Carousel } from '../../../../components/Carousel/Carousel';
import { Col, Div, Grid, Paragprah, Row, Small } from '../../../../theme/components';
import { RichText } from 'prismic-react-tools';
import { Section } from '../../../../components/Section/Section';
import { TestimonialsCarousel } from './Testimonials.style';
import PropTypes from 'prop-types';
import React from 'react';

export const Testimonials = props => {
  const { heading, items } = props;

  return (
    <Section heading={heading} id="testimonials">
      <Grid mt={{ md: 4.5, sm: 3.5, xs: 2.25 }}>
        <Row>
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col lg={6} md={8} sm={9} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2}>
            <TestimonialsCarousel>
              <Carousel autoplay normalizeHeight withBullets>
                {items.map(({ name, positionAndCompany, testimonial }, index) => (
                  <Div key={index}>
                    <Paragprah as="div" n05>
                      <RichText content={testimonial} />
                    </Paragprah>
                    <Paragprah mt={1.5} n06 semibold>
                      {name}
                    </Paragprah>
                    <Small mt={0.5} n04 semibold>
                      {positionAndCompany}
                    </Small>
                  </Div>
                ))}
              </Carousel>
            </TestimonialsCarousel>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Testimonials.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      positionAndCompany: PropTypes.string,
      testimonial: PropTypes.any
    })
  )
};
