import { colors } from '../../../../theme';
import { generateProps } from 'styled-gen';
import { size } from 'polished';
import styled from 'styled-components';

const ContactsMenu = styled.ul`
  display: flex;
`;

const ContactsMenuItem = styled.li`
  & + & {
    margin-left: 0.5rem;
  }

  a {
    ${size('1.25rem')}

    align-items: flex-end;
    color: inherit;
    display: flex;
    justify-content: center;

    &:hover {
      color: ${colors.n04};
    }
  }
`;

const ContactsWrapper = styled.div`
  color: ${colors.n03};
  display: inline-flex;

  ${generateProps};
`;

export { ContactsMenu, ContactsMenuItem, ContactsWrapper };
