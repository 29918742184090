import { Div } from '../../../../theme/components/Div/Div';
import { colors } from '../../../../theme';
import { mq } from 'styled-gen';
import { position, size } from 'polished';
import styled, { css } from 'styled-components';

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 5rem 0;
  position: relative;

  ${mq.tablet(css`
    padding: 7.5rem 0;
  `)}
`;

const HeroWrapper = styled(Div).attrs({
  as: 'section'
})`
  background-color: ${colors.n06};
  padding-bottom: 7.5rem;
  position: relative;
  z-index: 10;
`;

const HeroImage = styled.div`
  ${position('absolute')};
  ${size('100%')};

  background-image: url('img/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export { HeroContent, HeroImage, HeroWrapper };
