import { Button, Col, Grid, Paragprah, Row } from '../../../../theme/components';
import { Carousel } from '../../../../components/Carousel/Carousel';
import { PeopleCard } from './PeopleCard';
import { PeopleCardsCol, PeopleCardsWrapper } from './People.style';
import { RichText } from 'prismic-react-tools';
import { Section } from '../../../../components/Section/Section';
import { useCommonData } from '../../../../components/CommonDataProvider/CommonDataProvider';
import PropTypes from 'prop-types';
import React from 'react';

export const People = props => {
  const {
    advisoryBoard,
    advisoryBoardLabel,
    applyForPositionButtonLabel,
    applyForPositionText,
    heading,
    managingTeam,
    managingTeamLabel
  } = props;

  const { email } = useCommonData();

  const carouselItems = [managingTeam, advisoryBoard];

  return (
    <Section heading={heading} id="people">
      <Grid>
        <Row>
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col lg={10} md={8} sm={9} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2} mt={2.5}>
            <Carousel tabs={[managingTeamLabel, advisoryBoardLabel]} withGradient>
              {carouselItems.map((peopleItem, peopleItemIndex) => (
                <PeopleCardsWrapper key={peopleItemIndex}>
                  {peopleItem.map((person, index) =>
                    person.isVisible === 'yes' ? (
                      <PeopleCardsCol key={index}>
                        <PeopleCard {...person} />
                      </PeopleCardsCol>
                    ) : null
                  )}
                </PeopleCardsWrapper>
              ))}
            </Carousel>
          </Col>
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col lg={4} md={4} sm={4} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2} mt={8.75} pb={1.5}>
            <Paragprah as="div">
              <RichText content={applyForPositionText} />
            </Paragprah>
            <Button href={`mailto:${email}`} mt={1.5}>
              {applyForPositionButtonLabel}
            </Button>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

People.propTypes = {
  advisoryBoard: PropTypes.arrayOf(PropTypes.object),
  advisoryBoardLabel: PropTypes.string,
  applyForPositionButtonLabel: PropTypes.string,
  applyForPositionText: PropTypes.any,
  heading: PropTypes.string,
  managingTeam: PropTypes.arrayOf(PropTypes.object),
  managingTeamLabel: PropTypes.string
};
