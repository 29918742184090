import { mq } from 'styled-gen';
import styled, { css } from 'styled-components';

const ServicesCardCol = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  &:first-of-type {
    ${mq.tabletLandscape(css`
      flex-shrink: 0;
      width: 35%;
    `)}
  }

  & + & {
    margin-top: 1.5rem;

    ${mq.tabletLandscape(css`
      margin-left: 2rem;
      margin-top: 0;
    `)}
  }

  p + p {
    margin-top: 1rem;
  }
`;

const ServicesCardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem;

  ${mq.tablet(css`
    padding: 2.5rem;
  `)}

  ${mq.tabletLandscape(css`
    flex-direction: row;
    padding: 3.125rem;
  `)}
`;

const ServicesCardIcon = styled.img`
  display: none;
  margin-top: auto;

  ${mq.tabletLandscape(css`
    display: block;
  `)}
`;

export { ServicesCardCol, ServicesCardContent, ServicesCardIcon };
