import { Col, Grid, Paragprah, Row, XLarge } from '../../../../theme/components';
import { RichText } from 'prismic-react-tools';
import { Section } from '../../../../components/Section/Section';
import PropTypes from 'prop-types';
import React from 'react';

export const About = props => {
  const { heading, intro, text } = props;

  return (
    <Section heading={heading} id="about" mt={{ md: 10, sm: 6, xs: 4 }}>
      <Grid>
        <Row>
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col md={6} sm={9} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2}>
            <XLarge as="div" mt={2.5}>
              <RichText content={intro} />
            </XLarge>
            <Paragprah as="div" mt={2.5}>
              <RichText content={text} />
            </Paragprah>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

About.propTypes = {
  heading: PropTypes.string,
  intro: PropTypes.any,
  text: PropTypes.any
};
