import { colors } from '../../variables';
import { generateProps, variations } from 'styled-gen';
import { rgba, size } from 'polished';
import styled, { css } from 'styled-components';

const sizeVariations = {
  bookCover: css`
    ${size('16.625rem', '11.5rem')};

    box-shadow: none;
    border-radius: 0.5rem;

    & > .card-content {
      border-radius: 0.5rem;
    }
  `,

  default: css`
    border-radius: 1rem;

    & > .card-content {
      border-radius: 1rem;
    }
  `
};

export const Card = styled.div`
  box-shadow: 0 0 2.5rem ${rgba(colors.n06, 0.08)};
  position: relative;

  ${variations(sizeVariations)};
  ${generateProps};
`;

export const CardContent = styled.div.attrs({
  className: 'card-content'
})`
  height: 100%;
  overflow: hidden;

  ${({ bkgImage }) =>
    bkgImage &&
    css`
      background-image: url('${bkgImage}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `};

  ${generateProps};
`;
