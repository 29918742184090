const url = 'https://script.google.com/macros/s/AKfycbzukfTHcjXfaizpT45MR_RpNrs_oeY5PQHqFoGyH7T-pWGiZFg/exec';

export class SubmitContact {
  static submit = async contact => {
    try {
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `${url}?contact=${encodeURIComponent(contact)}&createdAt=${encodeURIComponent(new Date().toDateString())}`
      );

      return response;
    } catch (error) {
      return error;
    }
  };
}
