import { Icon } from '../Icon/Icon';
import { colors } from '../..';
import { generateProps, mq } from 'styled-gen';
import { size } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const ButtonWrapper = styled.button`
  border: 0;
  color: ${colors.n03};
  outline: 0;

  svg {
    ${size('1rem')};
  }

  ${mq.tabletLandscape(css`
    &:hover {
      color: ${colors.n05};
    }
  `)}

  ${generateProps};
`;

export const IconButton = props => {
  const { icon, ...forwardProps } = props;

  return (
    <ButtonWrapper
      as={forwardProps.href && 'a'}
      rel={forwardProps.href && 'noreferrer noopener'}
      target={forwardProps.href && '_blank'}
      {...forwardProps}
    >
      <Icon icon={icon} />
    </ButtonWrapper>
  );
};

IconButton.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string
};
