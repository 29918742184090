import { colors } from '../../../../theme';
import { mq } from 'styled-gen';
import { rgba, size } from 'polished';
import styled, { css } from 'styled-components';

const InsightCoverContent = styled.div`
  ${size('100%')};

  align-items: center;
  background-color: ${rgba(colors.n01, 0.9)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;

  a {
    margin-top: 3rem;
  }

  &:hover {
    opacity: 1;

    a {
      margin-top: 1rem;
    }
  }
`;

const InsightWrapper = styled.div`
  ${mq.tablet(css`
    padding: 1.25rem 1rem;
    width: 50%;
  `)}

  ${mq.tabletLandscape(css`
    width: 33.3333%;
  `)}

  ${mq.desktop(css`
    width: 25%;
  `)}
`;

const InsightsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${mq.tablet(css`
    justify-content: flex-start;
    margin: -1.25rem -1rem;
  `)}
`;

export { InsightCoverContent, InsightWrapper, InsightsWrapper };
