import { colors, fonts } from '../../theme';
import { ease, mq, transitions } from 'styled-gen';
import { linearGradient, position, rgba, size } from 'polished';
import styled, { css } from 'styled-components';

const CarouselBullet = styled.a`
  ${size('0.5rem')};

  background-color: ${({ isActive }) => (isActive ? colors.p06 : colors.n03)};
  border-radius: 50%;
  display: inline-block;

  & + & {
    margin-left: 0.5rem;
  }
`;

const CarouselBullets = styled.div`
  position: relative;
  margin-top: 3rem;
`;

const CarouselItems = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  width: 100%;

  ${({ elHeight }) =>
    elHeight &&
    css`
      height: ${elHeight / 16}rem;
    `}
`;

const CarouselItem = styled.div`
  ${transitions('transform', 500, ease.inOutCubic)};

  flex-shrink: 0;
  height: 100%;
  margin-bottom: auto;
  width: 100%;
  padding: 3rem;

  ${({ activeItem }) => css`
    transform: translateX(-${activeItem * 100}%);
  `}
`;

const CarouselLeftGradient = styled.div`
  ${position('absolute', '-2rem', '100%', '-2rem', null)};
  ${size(null, '100vw')};

  position: absolute;
  z-index: 1;
  /* height: calc(${({ carouselHeight }) => `${carouselHeight / 16}rem`} + 4rem); */

  ${linearGradient({
    colorStops: [`${colors.n01} 97%`, `${rgba(colors.n01, 0)} 100%`],
    fallback: 'transparent',
    toDirection: '90deg'
  })}
`;

const CarouselRow = styled.div`
  ${transitions('height', 500, ease.inOutCubic)};

  height: ${({ carouselHeight }) => carouselHeight / 16}rem;
  position: relative;
  overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'visible')};
  transform: translateX(-3rem);
  margin: -3rem 0;
  width: calc(100% + 6rem);
`;

const CarouselTabButton = styled.button`
  background-color: transparent;
  border: 0;
  color: ${colors.n04};
  font-size: 0.75rem;
  font-weight: ${fonts.weights.semibold};
  outline: 0;
  padding: 0;
  position: relative;

  & + & {
    margin-left: 2rem;
  }

  ${({ isActive }) =>
    isActive
      ? css`
          color: ${colors.n06};

          &::after {
            ${position('absolute', null, 0, '-0.625rem')};
            ${size('0.0625rem', '100%')};

            content: '';
            background-color: ${colors.p06};
          }
        `
      : css`
          ${mq.tabletLandscape(css`
            cursor: pointer;

            &:hover {
              color: ${colors.n05};
            }
          `)}
        `}
`;

const CarouselTabButtonsWrapper = styled.div`
  margin-bottom: 2.74rem;
  margin-right: -2rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  padding-right: 2rem;
  position: relative;
  white-space: nowrap;
  z-index: 10;
`;

const CarouselWrapper = styled.div`
  display: block;
  position: relative;
`;

export {
  CarouselBullet,
  CarouselBullets,
  CarouselItem,
  CarouselItems,
  CarouselLeftGradient,
  CarouselRow,
  CarouselTabButton,
  CarouselTabButtonsWrapper,
  CarouselWrapper
};
