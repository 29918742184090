import { Col, Grid, Heading2, Row } from '../../theme/components';
import { Element } from 'react-scroll';
import { colors } from '../../theme';
import { generateProps, mq, variations } from 'styled-gen';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const colorVariations = {
  light: css`
    background-color: ${colors.n02};
    padding: 3.75rem 0;

    ${mq.tablet(css`
      padding: 5.5rem 0;
    `)}

    ${mq.tabletLandscape(css`
      padding: 7.5rem 0;
    `)}
  `
};

const SectionWrapper = styled.div`
  margin-top: 7.5rem;
  overflow: hidden;

  ${mq.tablet(css`
    margin-top: 10rem;
  `)}

  ${mq.desktop(css`
    margin-top: 15rem;
  `)}

  ${variations(colorVariations)};
  ${generateProps};
`;

export const Section = props => {
  const { children, heading, headingProps, id, renderInHeading, ...forwardProps } = props;

  return (
    <Element name={id}>
      <SectionWrapper {...forwardProps}>
        <Grid>
          <Row>
            {/* eslint-disable-next-line react/jsx-sort-props */}
            <Col lg={10} md={8} sm={9} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2}>
              {(heading || renderInHeading) && (
                <Heading2 n06 {...headingProps}>
                  {heading ? heading : null}
                  {typeof renderInHeading === 'function' ? renderInHeading() : null}
                </Heading2>
              )}
            </Col>
          </Row>
        </Grid>
        {children}
      </SectionWrapper>
    </Element>
  );
};

Section.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  headingProps: PropTypes.object,
  id: PropTypes.string,
  renderInHeading: PropTypes.func
};
