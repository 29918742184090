import {
  Button,
  Card,
  CardContent,
  Col,
  Grid,
  Heading3,
  Img,
  Medium,
  Paragprah,
  Row
} from '../../../../theme/components';
import { Carousel } from '../../../../components/Carousel/Carousel';
import { RichText, extractFromCamelCase } from 'prismic-react-tools';
import { Section } from '../../../../components/Section/Section';
import { ServicesCardCol, ServicesCardContent, ServicesCardIcon } from './Services.style';
import PropTypes from 'prop-types';
import React from 'react';

export const Services = props => {
  const { heading, items, ...investmentData } = props;

  const tabs = items.map(({ heading }) => heading);
  const investment = extractFromCamelCase(investmentData, 'investment');

  return (
    <Section heading={heading} id="services">
      <Grid pb={2}>
        <Row>
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col lg={9} md={8} sm={9} xs={12} smOffset={1.5} mdOffset={3} lgOffset={2} mt={2.5}>
            <Carousel normalizeHeight showOverflow tabs={tabs} withGradient>
              {items.map(({ content, heading, icon: { url: icon }, subheading }, index) => (
                <Card h="100%" key={index}>
                  <CardContent>
                    <ServicesCardContent>
                      <ServicesCardCol>
                        <Heading3>{heading}</Heading3>
                        {subheading && (
                          <Paragprah mt={1.5} n04>
                            {subheading}
                          </Paragprah>
                        )}
                        {icon && <ServicesCardIcon src={icon} />}
                      </ServicesCardCol>
                      <ServicesCardCol>
                        <Medium as="div">
                          <RichText content={content} />
                        </Medium>
                      </ServicesCardCol>
                    </ServicesCardContent>
                  </CardContent>
                </Card>
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row mt={{ md: 10, sm: 7.5, xs: 5 }}>
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col sm={2} xs={false} smOffset={2.25} mdOffset={4} lgOffset={3}>
            <Img h="200px" src={investment.image.url} />
          </Col>
          {/* eslint-disable-next-line react/jsx-sort-props */}
          <Col lg={4} sm={4} smOffset={1} mdOffset={0.35} lgOffset={0.65} xs={12} mt={{ sm: 0, xs: 2 }}>
            <Heading3>{investment.heading}</Heading3>
            <Medium as="div" mt={1.5}>
              <RichText content={investment.text} />
            </Medium>
            <Button href={investment.buttonUrl} mt={1.5}>
              {investment.buttonLabel}
            </Button>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Services.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any,
      heading: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string
      }),
      subheading: PropTypes.string
    })
  )
};
