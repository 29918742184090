import { Col, Grid, Icon, Row } from '../../../../theme/components';
import { Contacts } from '../Contacts/Contacts';
import { Curve } from '../../../SVGs';
import {
  FooterBackToTopButton,
  FooterContent,
  FooterCurve,
  FooterLogo,
  FooterNote,
  FooterWrapper
} from './Footer.style';
import { animateScroll } from 'react-scroll';
import { useCommonData } from '../../../CommonDataProvider/CommonDataProvider';
import React from 'react';

export const Footer = () => {
  const { contactsAddress, footerFootnote } = useCommonData();

  return (
    <FooterWrapper>
      <FooterCurve>
        <Curve />
      </FooterCurve>
      <FooterContent>
        <Grid>
          <Row middle="xs">
            {/* eslint-disable-next-line react/jsx-sort-props */}
            <Col lg={3} md={3} xs={12} mdOffset={1} lgOffset={0} tAlign={{ md: 'left', xs: 'center' }}>
              <FooterBackToTopButton onClick={animateScroll.scrollToTop}>
                <Icon icon="arrowUp" />
              </FooterBackToTopButton>
            </Col>
            <Col lg={9} md={7} mt={{ md: 0, xs: 2 }} tAlign={{ md: 'right', xs: 'center' }} xs={12}>
              <Contacts n05 />
            </Col>
          </Row>
          <Row>
            {/* eslint-disable-next-line react/jsx-sort-props */}
            <Col lg={3} md={3} xs={12} mdOffset={1} lgOffset={0} tAlign={{ md: 'left', xs: 'center' }}>
              <FooterLogo>
                <Icon icon="ab" />
              </FooterLogo>
            </Col>
            <Col lg={9} md={7} tAlign={{ md: 'left', xs: 'center' }} xs={12}>
              <FooterNote>
                {footerFootnote} {contactsAddress}
              </FooterNote>
            </Col>
          </Row>
        </Grid>
      </FooterContent>
    </FooterWrapper>
  );
};
