import { colors, fonts } from '../../../../theme';
import { mq } from 'styled-gen';
import styled, { css } from 'styled-components';

const placeholder = css`
  color: ${colors.n04};
`;

const CtaInputType = styled.input.attrs({
  type: 'text'
})`
  border: 0;
  outline: 0;
  border-radius: ${3.25 / 2}rem;
  font-family: ${fonts.families.sans};
  font-size: 1.125rem;
  font-weight: ${fonts.weights.light};
  height: 3.25rem;
  padding: 0 1.5rem;
  width: 100%;

  ::placeholder,
  ::-webkit-input-placeholder {
    ${placeholder};
  }

  :-ms-input-placeholder {
    ${placeholder};
  }
`;

const CtaInputWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mq.tablet(css`
    background-color: ${colors.n01};
    border-radius: ${3.25 / 2}rem;
    flex-direction: row;
  `)}
`;

export { CtaInputType, CtaInputWrapper };
