import { mq } from 'styled-gen';
import styled, { css } from 'styled-components';

const PeopleCardsCol = styled.div`
  width: 100%;
  padding: 1.25rem 1rem;

  ${mq.tablet(css`
    width: 50%;
  `)}

  ${mq.desktop(css`
    width: 33.3333%;
  `)}
`;

const PeopleCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1.25rem -1rem;
`;

export { PeopleCardsCol, PeopleCardsWrapper };
