import { colors, container, flexboxgrid, fonts, generator } from './variables';

const theme = {
  colors,
  container,
  flexboxgrid,
  fonts,

  generator
};

export { colors, fonts, generator };

export default theme;
