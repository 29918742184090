import { Icon as BaseIcon, Button, Div, GlobalStyle, Heading1, XLarge } from '../theme/components';
import { FourOFourNumber } from '../components/SVGs/404';
import { mq } from 'styled-gen';
import { position, size } from 'polished';
import React from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import theme, { colors } from '../theme';

const FourOFourContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const Icon = styled(BaseIcon).attrs({
  icon: 'ab'
})`
  ${size('3.5rem')};

  color: ${colors.p06};
  align-self: center;
  position: absolute;
  top: 10%;

  ${mq.tablet(css`
    top: 25%;
  `)}
`;

const FourOFourNumbersWrapper = styled.div`
  ${position('absolute', null, 0, 0, 0)};

  padding: 0 1rem;
  text-align: center;
  z-index: 0;

  svg {
    max-width: 72rem;
  }

  ${mq.tablet(css`
    ${position('absolute', null, 0, 'auto', 0)};

    padding: 0 3rem;
  `)}
`;

const FourOFourWrapper = styled.div`
  ${size('100vh', '100vw')};

  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NotFoundPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <FourOFourWrapper>
        <FourOFourNumbersWrapper>
          <FourOFourNumber />
        </FourOFourNumbersWrapper>
        <Icon />
        <FourOFourContent>
          <Heading1 p06>ho noooo!</Heading1>
          <XLarge center mt={1.5}>
            You just hit a route that doesn’t exist
          </XLarge>
          <Div mt={1.5}>
            <Button href="/" target="_self">
              go to homepage
            </Button>
          </Div>
        </FourOFourContent>
      </FourOFourWrapper>
    </ThemeProvider>
  );
};

export default NotFoundPage;
