import { About, Cta, Hero, Insights, Partners, People, Services, Testimonials } from './components';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const Homepage = props => {
  const {
    pageContext: { document }
  } = props;

  const about = extractFromCamelCase(document, 'about');
  const cta = extractFromCamelCase(document, 'cta');
  const hero = extractFromCamelCase(document, 'hero');
  const insights = extractFromCamelCase(document, 'insights');
  const partners = extractFromCamelCase(document, 'partners');
  const people = extractFromCamelCase(document, 'people');
  const services = extractFromCamelCase(document, 'services');
  const testimonials = extractFromCamelCase(document, 'testimonials');

  return (
    <>
      <Hero {...hero} />
      <About {...about} />
      <Services {...services} />
      <Cta {...cta} />
      <Partners {...partners} />
      <People {...people} />
      <Insights {...insights} />
      <Testimonials {...testimonials} />
    </>
  );
};

Homepage.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(Homepage);
