import { extractFromCamelCase } from 'prismic-react-tools';
import { get } from 'lodash';

export const getSeoFromDocument = document => {
  const documentSeo = {};

  if (typeof document.title === 'string') {
    documentSeo.title = document.title;
  }

  if (typeof document.description === 'string') {
    documentSeo.description = document.description;
  }

  if (typeof document.keywords === 'string') {
    documentSeo.keywords = document.keywords;
  }

  if (get(document, 'image.url')) {
    documentSeo.image = document.image;
  }

  return {
    ...extractFromCamelCase(document, 'seo'),
    ...documentSeo
  };
};
